<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="分组名称">
          <el-input v-model="searchForm.groupName" autocomplete="off" size="small" placeholder="请输入分组名称" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="initTable">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" size="small" icon="el-icon-plus" @click="open('add')">新增分组</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="groupList" stripe style="width: 100%" height="100%">
        <el-table-column label="序号" type="index" width="100" :index='indexMethod'> </el-table-column>
        <el-table-column prop="groupName" label="分组名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="修改" placement="top">
              <el-button size="mini" @click="open('edit',scope.row)" type="warning" icon="el-icon-edit" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button type="danger" @click="deleteGroup(scope.row.id)" size="mini" icon="el-icon-delete-solid" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="查看分组" placement="top">
              <el-button type="primary" @click="open('group',scope.row)" size="mini" icon="el-icon-search" circle></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 单位弹窗 -->
    <el-dialog :title="optFlag?'添加分组':'修改分组'" :visible.sync="unitShow" width="30%" @close="clear" v-dialogDrag :close-on-click-modal="false">
      <el-form :model="groupForm" ref="groupForm">
        <el-form-item label="分组名称" :label-width="formLabelWidth">
          <el-input v-model="groupForm.groupName" placeholder="请输入分组名称" size="small" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="unitShow = false">取 消</el-button>
        <el-button type="primary" @click="addGroup" v-if="optFlag">添 加</el-button>
        <el-button type="primary" @click="updateGroup" v-if="!optFlag">修 改</el-button>
      </div>
    </el-dialog>
    <!-- 分组单位列表 -->
    <el-drawer title="分组列表" :visible.sync="listShow" :direction="direction" :before-close="handleClose" size="40%">
      <div class="container noangle">
        <div class="header" style="justify-content: flex-end">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item>
              <el-button type="success" size="small" @click="open('map')" icon="el-icon-plus">添加单位</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="content" v-loading="loading2">
          <el-table :data="unitList" stripe style="width: 100%" height="100%">
            <el-table-column prop="unitName" label="单位名称" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="legalName" label="法人" show-overflow-tooltip width="60px">
            </el-table-column>
            <el-table-column prop="legalPhone" label="法人电话" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="address" label="地址" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="操作" width="60px">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" content="移除单位" placement="top">
                  <el-button type="danger" @click="removeUnit(scope.row.id)" size="mini" icon="el-icon-delete-solid" circle></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="footer">
          <el-pagination @current-change="handleListChange" :current-page.sync="listPage" :page-size="15" layout="total, prev, pager, next, jumper" :total="listTotal">
          </el-pagination>
        </div>
      </div>
    </el-drawer>
    <!-- 选择单位 -->
    <el-dialog v-if="mapShow" :visible.sync="mapShow" width="80%" top="3%" :close-on-click-modal="false" class="map">
      <div class="container_content">
        <div class="left_box" v-show="showLeft">
          <div class="search_box">
            <div>
              <div class="title"> 单位列表 </div>
              <el-input v-model="keyword" placeholder="请输入名称进行搜索" clearable size="small" @input="initUnit"></el-input>
            </div>
          </div>
          <div v-loading="loading3">
            <el-table :data="noGroupList" height="100%">
              <el-table-column prop="unitName" label="名称" show-overflow-tooltip> </el-table-column>
              <el-table-column label="定位" width="60px">
                <template slot-scope="scope">
                  <el-button type="text" size="medium" @click="pointTo(scope.row, 'localtion')" icon="el-icon-location" style="font-size:18px"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div>
            <el-pagination :pager-count="5" small @current-change="handleUnitChange" :current-page.sync="unitPage" :page-size="15" layout="total,prev, pager, next" :total="unitTotal">
            </el-pagination>
          </div>
        </div>
        <div class="right_box" v-loading="loading1">
          <div class="map_box">
            <baidu-map class="bmView" :scroll-wheel-zoom="true" @ready="handler"> </baidu-map>
          </div>
          <div class="left_button" @click="showLeft = !showLeft">
            <i class="el-icon-d-arrow-left" v-show="showLeft"></i>
            <i class="el-icon-d-arrow-right" v-show="!showLeft"></i>
          </div>
          <transition name="el-zoom-in-center">
            <div class="link-tag" v-show="unitListIds.length>0">
              <div class="link-title">已选单位</div>
              <div class="tag-list">
                <el-tag type="success" size="normal" closable @close="delUnitFromGroup(item)" v-for="(item,i) in unitListIds" :key="i">{{item.unitName}}</el-tag>
              </div>
              <div class="link-btn">
                <el-button type="primary" round size="mini" @click="addUnitToGroup">添 加</el-button>
              </div>
            </div>
          </transition>
          <div class="close" @click="mapShow=false"> <i class="el-icon-close"></i> </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
export default {
  components: {
    BaiduMap
  },
  data () {
    return {
      location: {
        lng: 0,
        lat: 0
      },
      zoom: 18,
      addressKeyword: '',
      currentPage: 1,
      listPage: 1,
      unitPage: 1,
      formLabelWidth: '80px',
      total: 0,
      listTotal: 0,
      unitTotal: 0,
      groupList: [],
      unitList: [],
      noGroupList: [], // 未分组单位列表
      unitListIds: [], // 选中单位列表
      groupId: 0, // 单位ID
      groupForm: {
        groupName: ''
      }, // 建筑表单
      groupFormCopy: {},
      rules: {
        typeId: [
          { required: true, message: '请选择组类型', trigger: 'change' }
        ],
        equipmentCode: [
          { required: true, message: '请输入组特征码', trigger: 'blur' }
        ],
        stationCode: [
          { pattern: /(^$)|(^[\u4E00-\u9FA5a-zA-Z0-9]{1,10}$)/, message: '请输入合法的基站特征码', trigger: 'blur' }
        ],
        contactPhone: [
          { pattern: /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/, message: '请输入正确的的手机号', trigger: 'blur' }
        ]
      },
      searchForm: {},
      unitShow: false,
      listShow: false,
      mapShow: false,
      showLeft: true,
      optFlag: false,
      direction: 'rtl',
      loading: false,
      loading1: false,
      loading2: false,
      loading3: false,
      activeName: 'first',
      keyword: '',
      map: null,
      // baidu map api
      point: null,
      infoWindow: null,
      marker: null
    }
  },
  watch: {
    mapShow () {
      if (!this.mapShow) {
        this.unitListIds = []
      }
    }
  },
  created () {
    this.initTable()
    this.groupFormCopy = JSON.stringify(this.groupForm)
    window.addUnitToGroupFake = this.addUnitToGroupFake
    window.checkListCopy = this.checkListCopy
  },
  methods: {
    // 分组序号
    indexMethod (index) {
      const curpage = this.currentPage // 单前页码，具体看组件取值
      const limitpage = 10 // 每页条数，具体是组件取值
      return index + 1 + (curpage - 1) * limitpage
    },
    async handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    async handleListChange (val) {
      this.listPage = val
      this.initList()
    },
    async handleUnitChange (val) {
      this.unitPage = val
      this.initUnit()
    },
    async initTable () {
      const data = {}
      if (this.searchForm.groupName) {
        data.groupName = this.searchForm.groupName
      }
      data.pageNo = this.currentPage
      data.pageSize = 10
      const { data: result } = await this.$axios.get('/group/list', { params: data })
      if (result.code === 200) {
        this.groupList = result.data.result
        this.total = result.data.total
      } else {
        this.$message.error(result.msg)
      }
    },
    // 初始化单位表格
    async initList () {
      const data = {
        pageNo: this.listPage,
        groupId: this.groupId,
        pageSize: 15
      }
      this.loading2 = true
      const { data: result } = await this.$axios.get('/unit/listWeb', { params: data })
      if (result.code === 200) {
        this.loading2 = false
        this.unitList = result.data.result
        this.listTotal = result.data.total
      } else {
        this.loading2 = false
        this.$message.error(result.msg)
      }
    },
    async initUnit () {
      const data = {
        pageNo: this.unitPage,
        pageSize: 15,
        unitName: this.keyword
      }
      this.loading3 = true
      const { data: result } = await this.$axios.get('/unit/listWeb', { params: data })
      if (result.code === 200) {
        this.noGroupList = result.data.result
        this.unitTotal = result.data.total
        this.loading3 = false
        this.drawUnit()
      } else {
        this.$message.error(result.msg)
        this.loading3 = false
      }
    },
    // 删除某条数据
    async deleteGroup (id) {
      const confirm = await this.$confirm('此操作将永久删除该单位, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/group/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          const totalPage = Math.ceil((this.total - 1) / 10) // 总页数
          this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
          this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
          this.initTable()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    // 修改某条数据
    async updateGroup () {
      this.$refs.groupForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.put(`/group/edit/${this.groupId}`, this.groupForm)
          if (result.code === 200) {
            this.$message.success('修改成功')
            this.unitShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 新增培训
    async addGroup () {
      this.$refs.groupForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.post('/group/addGroup', this.groupForm)
          if (result.code === 200) {
            this.$message.success('新增成功')
            const totalPage = Math.ceil((this.total + 1) / 10) // 总页数
            this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
            this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
            this.unitShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    async addUnitToGroup () {
      const confirm = await this.$confirm('是否添加这些单位至该分组?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const unitListIds = []
        for (let i = 0; i < this.unitListIds.length; i++) {
          unitListIds.push(this.unitListIds[i].id)
        }
        const { data: result } = await this.$axios.post('/group/add', { groupId: this.groupId, unitListIds: unitListIds })
        if (result.code === 200) {
          this.$message.success('添加成功')
          this.mapShow = false
          this.initList()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    addUnitToGroupFake (name, id) {
      const flag = _.some(this.unitListIds, {
        unitName: name,
        id: id
      })
      if (!flag) {
        this.unitListIds.push({
          unitName: name,
          id: id
        })
      } else {
        this.$message.error('不能添加相同的单位')
      }
    },
    delUnitFromGroup (tag) {
      this.unitListIds.splice(this.unitListIds.indexOf(tag), 1)
    },
    async removeUnit (id) {
      const confirm = await this.$confirm('是否添加这些单位至该分组?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete('/group/remove', { data: { groupId: this.groupId, unitListIds: [id] } })
        if (result.code === 200) {
          this.initList()
          this.$message.success('移除成功')
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    handler ({ BMap, map }) {
      this.map = map
      this.point = (lng, lat) => {
        return new BMap.Point(lng, lat)
      }
      this.infoWindow = (content, opts) => {
        return new BMap.InfoWindow(content, opts)
      }
      this.marker = (obj) => {
        return new BMap.Marker(obj)
      }
      const point = this.point(this.pointLng, this.pointlat)
      this.map.centerAndZoom(point, 16)
    },
    open (flag, row) {
      switch (flag) {
        case 'add':
          this.unitShow = true
          this.$nextTick(() => {
            this.$refs.groupForm.clearValidate()
          })
          this.optFlag = true
          break
        case 'edit':
          this.unitShow = true
          this.optFlag = false
          this.$nextTick(() => {
            this.$refs.groupForm.clearValidate()
          })
          this.groupForm = JSON.parse(JSON.stringify(row))
          this.groupId = row.id
          break
        case 'map':
          this.initUnit()
          this.mapShow = true
          break
        case 'group':
          this.groupId = row.id
          this.listShow = true
          this.initList()
      }
    },
    // 关闭弹窗清空表单
    clear () {
      this.groupForm = JSON.parse(this.groupFormCopy)
      // this.$refs.groupForm.resetFileds()
      this.groupForm = {
        groupName: ''
      }
      this.$refs.groupForm.clearValidate()
    },
    // 关闭抽屉
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => { })
    },
    /* 清除指定类型的 marker */
    clearMarker (type) {
      const allOverlay = this.map.getOverlays()
      if (allOverlay) {
        allOverlay.forEach(item => {
          if (item.type === type) {
            this.map.removeOverlay(item)
            return false
          }
        })
      }
    },
    /* 画出单位的 marker */
    drawUnit () {
      this.noGroupList.forEach((item, index) => {
        if (index === 0) {
          this.pointTo(item, 'localtion')
        }
        const point = this.point(item.lng, item.lat)
        const marker = this.marker(point)
        marker.type = 'unit'
        this.map.addOverlay(marker)
        marker.addEventListener('click', (e) => {
          this.pointTo(item, 'localtion')
        })
      })
    },
    /* 信息框 */
    drawInfoWindow (item, point) {
      const imgUrl = require('../../assets/img/failed.png')
      let content = `<div style="display: flex;flex-direction: column;justify-content: space-between;">
                        <div style="display: flex;align-items: flex-start;justify-content: space-between;">`
      if (!item.unitImage) {
        content += `<div style="width: 100px;height:80px"><img src="${imgUrl}" width="100%" height="100%" alt="暂无图片"></div>`
      } else {
        content += `<div style="width: 100px;height:80px">
                    <img src="${item.unitImage}" style="width: 100%;height: 100%" />
                  </div>`
      }
      content += `<div style="padding-left: 10px">
                      <p>地址：${item.areaName}${item.address}</p>
                      <p>负责人：${item.legalName}</p>
                      <p>联系方式：${item.legalPhone}</p>
                      <p>安全等级：${item.monitorLevelName}</p>
                  </div>
              </div>
              <div style="display: flex;justify-content: flex-end;">
                  <input type="button" value="添加" onclick="return addUnitToGroupFake('${item.unitName}',${item.id})" style="cursor: pointer;background-color: #409EFF;margin-top: 10px;padding: 5px 20px;outline:none;border:none;border-radius: 20px;color: #fff"/>
              </div>
            </div>`
      const infoWindow = this.infoWindow(content, { title: item.unitName })
      this.map.openInfoWindow(infoWindow, point)
    },
    // 定位
    pointTo (item, flag) {
      const point = this.point(item.lng, item.lat)
      this.map.centerAndZoom(point, 18)
      if (flag === 'localtion') {
        this.drawInfoWindow(item, point)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 150px;
  height: 150px;
}
.dialog {
  margin: 0;
  padding: 10px;
  ::v-deep  .el-dialog__header {
    display: none !important;
  }
}
.demo-table-expand {
  font-size: 0;
  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
    ::v-deep  label {
      color: #99a9bf !important;
    }
  }
}
::v-deep  .el-table__expanded-cell {
  padding: 0 50px;
}
::v-deep  :focus {
  outline: 0;
}
.el-drawer__header {
  margin-bottom: 0;
}
.map {
  height: 100%;
  ::v-deep  .el-dialog__header {
    display: none !important;
  }
  ::v-deep  .el-dialog__body {
    padding: 0 !important;
  }
  i {
    font-size: 18px;
    cursor: pointer;
    color: #333;
  }
  i:hover {
    color: #409eff;
  }
  .container_content {
    width: 100%;
    height: 800px;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
    justify-content: space-between;
    .left_box {
      flex: 1;
      padding: 10px;
      display: flex;
      overflow: hidden;
      border-right: 1px solid #ccc;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: space-between;
      .search_box {
        display: flex;
        align-items: flex-end;
        justify-content: space-around;
        .title {
          text-align: left;
          font-weight: bold;
          font-size: 18px;
          padding-bottom: 10px;
          color: #fff;
        }
        > div {
          flex: 4;
          ::v-deep .el-input__inner {
            background-color: rgba(7, 104, 159, 0.15);
            color: #fff;
          }
        }
        > div:last-child {
          flex: 5;
          margin-left: 5px;
        }
      }
      > div:nth-child(2) {
        flex: 10;
        padding: 10px 0;
        overflow: hidden;
        ::v-deep .el-table,
        .el-table__expanded-cell {
          background-color: transparent;
          tr,
          th {
            background-color: transparent;
            color: #fff;
            .el-table__expand-icon {
              color: #fff;
            }
          }
          .el-table--enable-row-hover,
          .hover-row > td,
          tr:hover > td {
            background-color: rgba(7, 104, 159, 0.15) !important;
          }
          .el-table__row--striped > td {
            background-color: rgba(7, 104, 159, 0.15) !important;
          }
        }
      }
      > div:last-child {
        display: flex;
        justify-content: flex-start;
        box-sizing: border-box;
        ::v-deep .el-pagination {
          margin-right: auto;
          color: #fff;
          background-color: transparent;
          .el-pagination__total,
          .el-pagination__jump {
            color: #fff;
          }
          .btn-prev,
          .btn-next {
            background-color: transparent;
            color: #fff;
          }
          .el-pager li {
            background-color: transparent !important;
          }
          .btn-quicknext {
            color: #fff;
          }
        }
      }
    }
    .right_box {
      flex: 3;
      position: relative;
      .bmView,
      .map_box {
        width: 100%;
        height: 100%;
        position: relative;
      }
      .video {
        position: absolute;
        bottom: 20px;
        right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        z-index: 9999 !important;
        border: 1px solid #ccc;
        background-color: #f5f5f5;
      }
      .left_button {
        cursor: pointer;
        position: absolute;
        top: 370px;
        left: -1px;
        bottom: 370px;
        padding: 0 10px;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        z-index: 10;
        border: 1px solid #ccc;
        border-left: none;
        align-items: center;
        justify-content: center;
        border-radius: 0 10px 10px 0;
        background-color: #fff;
      }
      .close {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 18px;
        font-weight: bold;
        padding: 5px;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      }
      .link-tag {
        width: 85%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 60px;
        padding: 15px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        .link-title {
          font-size: 18px;
          color: #000;
          text-align: left;
          padding-bottom: 5px;
          border-bottom: 1px solid #ddd;
        }
        .tag-list {
          width: 100%;
          margin: 10px 0;
          .el-tag {
            margin-left: 10px;
          }
          .el-tag:first-child {
            margin-left: 0;
          }
        }
        .link-btn {
          border-top: 1px solid #ddd;
          padding-top: 5px;
          .el-button {
            float: right;
          }
        }
      }
    }
  }
  ::v-deep  .el-loading-mask {
    background-color: rgba(255, 255, 255, 0.5);
  }
  ::v-deep  .el-pagination {
    padding: 0;
  }
}
</style>
